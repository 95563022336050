import React  from 'react'
import { Grid, Typography, Box, makeStyles } from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { useTranslation } from 'react-i18next'

import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import { Layout } from '../components/layouts/main-layout'
// import { Brass } from 'components/logos/brass'
import { Calendar } from 'components/logos/calendar'
import { World } from 'components/logos/world'
// import { Psichas } from 'components/logos/psichas'

const useStyles = makeStyles(theme => ({
  heroContainer: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    opacity: 0.6,
    zIndex: 0,
  },
  psichas: {
    position: 'absolute',
    bottom: -20,
  },
  svg: {
    margin: 'auto',
    textAlign: 'center',
  },
  container: {
    maxWidth: 1200,
    margin: 'auto',
  },
  paper: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    borderRadius: 69,
    border: `4px solid ${theme.palette.primary.dark}`,
  },
  bold: {
    fontWeight: 'bold',
  },
}))

interface Props {
  width: Breakpoint;
}

const AboutPage = ({ width }: Props) => {
  const classes = useStyles()
  const CONTACT = 'viktorija@gmd.lt'
  const { t } = useTranslation()
  const isMobile = isWidthDown('md', width)
  const isSmall = isWidthDown('sm', width)

  return (
    <Layout
      pageName={t('page.about')}
      withCity={false}
      fullWidth
    >
      <Box p={2}>
        {/* <div className={classes.heroContainer}>
          <Brass width='100%' />
        </div> */}
        <div className={classes.container}>
          <Box pl={3} pr={3} mt={3}>
            <Box pb={2}>
              <Typography variant='h5' align='center' className={classes.bold}>
                {t('about.gmd')}
              </Typography>
            </Box>
            <Typography paragraph style={{
              maxWidth: 600,
              margin: 'auto'
            }}>
              {t('about.about-p1')}
            </Typography>
          </Box>

          <Box pt={3} pl={3} pr={3}>
            <Grid container spacing={2} direction={isMobile ? 'column' : 'row'}>
              <Grid
                container
                direction='column'
                justify='center'
                alignItems={isMobile ? 'center' : 'flex-end'}
                spacing={2}
                item lg={8} xs={12}
              >
                <Grid item>
                  <Typography variant='h5' align={isMobile ? 'left' : 'right'} className={classes.bold}>
                    {t('about.when')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography paragraph align={isMobile ? 'left' : 'right'}>
                    {t('about.about-p2')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item lg={4} xs={12} className={classes.svg}>
                <Calendar height={isSmall ? '200px' : '240px'} />
              </Grid>
            </Grid>
          </Box>

          <Box pt={3} pl={3} pr={3}>
            <Grid container spacing={2} direction={isMobile ? 'column-reverse' : 'row'}>
              <Grid item xs={12} lg={6} className={classes.svg}>
                <World height={isSmall? '140px' : '260px'} />
              </Grid>
              <Grid
                container
                direction='column'
                justify='center'
                alignItems={isMobile ? 'center' : 'flex-start'}
                spacing={2}
                item lg={6} xs={12}
              >
                <Grid item>
                  <Typography variant='h5' align={isMobile ? 'left' : 'left'} className={classes.bold}>
                    {t('about.abroad')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography paragraph align={isMobile ? 'left' : 'left'}>
                    {t('about.about-p3')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box pt={3} pl={3} pr={3}>
            <Typography paragraph align='center' className={classes.bold}>
              {t('about.join', { email: CONTACT })}
            </Typography>
          </Box>
        </div>
      </Box>
    </Layout>
  )
}

export default withWidth()(AboutPage)
